import { Input, Button, Select } from "antd";
import { useState } from "react";
import axios from "axios";
import "./index.css";
const { TextArea } = Input;
const { Option } = Select;

function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [msg, setMsg] = useState("");
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState("");

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const sendMsg = async () => {
    var failed = false;
    if (
      fname.length < 2 ||
      lname.length < 2 ||
      email.length < 2 ||
      type.length === "" ||
      msg.length < 2
    ) {
      failed = true;
    }
    if (failed === true) {
      setSuccess("");
      setAlert("Please fill out all required fields");
      await sleep(5000);
      setAlert("");
    } else {
      let messageObject = {
        fname: fname,
        lname: lname,
        email: email,
        tel: tel,
        company: company,
        type: type,
        msg: msg,
      };
      axios
        .post("https://api.hyperdisplay.tv/sendMessage", messageObject)
        .then(async () => {
          setAlert("");
          setSuccess("Message sent! Someone will reach out to you shortly.");
          await sleep(5000);
          setSuccess("");
        })
        .catch(async (e) => {
          console.log(e);
          setSuccess("");
          setAlert("Something went wrong, please try again later.");
          await sleep(5000);
          setAlert("");
        });
    }
  };
  return (
    <div className="contactForm">
      <div className="inputLine">
        <div className="inputGroup">
          <p>
            First Name <span>*</span>
          </p>
          <Input type="text" onChange={(e) => setFname(e.target.value)} />
        </div>
        <div className="inputGroup">
          <p>
            Last Name <span>*</span>
          </p>
          <Input type="text" onChange={(e) => setLname(e.target.value)} />
        </div>
      </div>
      <div className="inputLine">
        <div className="inputGroup">
          <p>
            Email Address <span>*</span>
          </p>
          <Input type="email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="inputGroup">
          <p>Phone number</p>
          <Input type="tel" onChange={(e) => setTel(e.target.value)} />
        </div>
      </div>
      <div className="inputLine">
        <div className="inputGroup">
          <p>Company Name</p>
          <Input type="text" onChange={(e) => setCompany(e.target.value)} />
        </div>
        <div className="inputGroup">
          <p>
            Type of your inquiry <span>*</span>
          </p>
          <Select
            placeholder="Select inquiry type"
            onChange={(e) => setType(e)}
          >
            <Option value="new">New Customer Inquiry</Option>
            <Option value="existing">Existing Customer Inquiry</Option>
            <Option value="tech">Technical Support</Option>
            <Option value="billing">Billing Inquiry</Option>
            <Option value="shipping">Shipping Inquiry</Option>
            <Option value="general">General Inquiry</Option>
          </Select>
        </div>
      </div>
      <div className="inputLine">
        <div className="inputArea">
          <p>
            Your Message <span>*</span>
          </p>
          <TextArea onChange={(e) => setMsg(e.target.value)} />
        </div>
      </div>
      <div className="inputLine">
        <div className="contactFooter">
          <Button className="sendContact" onClick={sendMsg}>
            Submit
          </Button>
          <p>
            Required fields are marked (<span>*</span>)
          </p>
          <p style={{ color: "red" }}>{alert}</p>
          <p style={{ color: "green" }}>{success}</p>
        </div>
      </div>
    </div>
  );
}
export default Contact;
