import Slider from "react-slick";
import coracle from "./assets/coracle.svg";
import dnnenterprises from "./assets/dnnenterprises.svg";
import imperialdynamicscorp from "./assets/imperialdynamicscorp.svg";
import nexustech from "./assets/nexustech.svg";

import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function MobilePartners() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 1,
    swipe: true,
    touchMove: true,
    draggable: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="sliderImageMbl">
        <img src={coracle} className="partnerLogo" alt="" />
      </div>
      <div className="sliderImageMbl">
        <img src={dnnenterprises} className="partnerLogo" alt="" />
      </div>
      <div className="sliderImageMbl">
        <img src={imperialdynamicscorp} className="partnerLogo" alt="" />
      </div>
      <div className="sliderImageMbl">
        <img src={nexustech} className="partnerLogo nexusLogo" alt="" />
      </div>
    </Slider>
  );
}
export default MobilePartners;
