import { Carousel } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

export function Testimonials() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 10000,
    autoplay: true,
    nextArrow: <ArrowRightOutlined className="testimonialArrow" />,
    prevArrow: <ArrowLeftOutlined className="testimonialArrow" />,
  };
  return (
    // <Carousel autoplay dots effect="scrollx" arrows={true}>
    <Carousel {...settings}>
      <div className="testimonialSlide">
        <p>
          We use HyperDisplay at our checkout counter to help upsell items.
          We've noticed a significant increase in sales after marketing
          promotions on our digital screens. The user interface is simple to use
          and makes managing over 120 screens a piece of cake!
        </p>
        <h3 style={{ opacity: "0.9" }}>Reggie Frank</h3>
        {/* <img src={starstop} className="ssTestimonial" alt="Star Stop Logo" /> */}
      </div>
      <div className="testimonialSlide">
        <p>
          We started of with two locations and a total of four screens. Being
          able to upload our breakfast, lunch and dinner menus remotely on a
          schedule has made managing our restaurants simple and easy. I wish I
          got HyperDisplay sooner.
        </p>
        <h3 style={{ opacity: "0.9" }}>Harley Francis</h3>
        {/* <img src={lostios} className="ltTestimonial" alt="Los Tios Logo" /> */}
      </div>
    </Carousel>
  );
}
export default Testimonials;
