import Slider from "react-slick";
import corpo from "./assets/corpo.png";
import gas from "./assets/gas.png";
import restaurant from "./assets/restaurant.png";

import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function SolutionsSlider() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    className: "solutionsSliderBlock",
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    swipe: true,
    touchMove: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="carouselITM">
        <img src={corpo} alt="" />
        <p>Corporate Offices</p>
      </div>
      <div className="carouselITM">
        <img src={gas} alt="" />
        <p>Gas Stations</p>
      </div>
      <div className="carouselITM">
        <img src={restaurant} alt="" />
        <p>Restaurants, Cafés and Bars</p>
      </div>
      <div className="carouselITM">
        <img src={corpo} alt="" />
        <p>Corporate Offices</p>
      </div>
      <div className="carouselITM">
        <img src={gas} alt="" />
        <p>Gas Stations</p>
      </div>
      <div className="carouselITM">
        <img src={restaurant} alt="" />
        <p>Restaurants, Cafés and Bars</p>
      </div>
    </Slider>
  );
}
export default SolutionsSlider;
