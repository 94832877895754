import { Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Panel } = Collapse;

export function FAQ() {
  const [sel, setSel] = useState(1);
  let questions = [
    {
      q: "What do I need to set up HyperDisplay?",
      a: "All you need is a screen and a wired internet connection. After that, it's just simply plug and play!",
      n: 1,
    },
    {
      q: "Is there a limit to the amount of files I can upload?",
      a: "No, we allow you to upload an unlimited amount of files, as long as they fit the size and format requirements!",
      n: 2,
    },
    {
      q: "What happens if the device loses connection?",
      a: "Once the device is set, it will work regardless of connection status. Whenever the connection comes back, it'll check if there are updates. Your marketing items will continue to display without any disturbances.",
      n: 3,
    },
    {
      q: "What kind of content is supported by HyperDisplay?",
      a: "We support video and photo assets in 16:9 or 9:16 aspect ratio (horizontal and vertical), depending on your needs!",
      n: 4,
    },
    {
      q: "Do I need any special skills to use HyperDisplay?",
      a: "Absolutely not! HyperDisplay was designed from scratch with a goal to make it easy to use for anyone, including not so tech savvy people!",
      n: 5,
    },
  ];
  return (
    <div className="faqs">
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition="right"
        ghost={true}
        className="faqCollapse"
        onChange={(v) => setSel(v)}
        accordion={true}
      >
        {questions.map((ques) => {
          return (
            <Panel
              header={ques.q}
              key={ques.n}
              className={`faqPanel ${sel === ques.n ? "faqActive" : ""}`}
            >
              <p>{ques.a}</p>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
export default FAQ;
